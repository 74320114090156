<template>
  <div class="categoryForm">
    <form v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="category_name">Category Name:</label>
        <input
          type="text"
          v-model="category.name"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('category_name'),
          }"
          name="category_name"
          id="category_name"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid category name.
        </div>
      </div>

      <div class="form-group">
        <label for="category_name">Category Slug:</label>
        <input
          type="text"
          v-model="category.slug"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('category_slug'),
          }"
          name="category_slug"
          id="category_slug"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid category name.
        </div>
      </div>

      <div class="form-group">
        <label for="body">Parent Category:</label>
        <select
          class="form-control"
          name="video"
          v-model="category.parent_category"
        >
          <option
            v-for="parent in parent_categories"
            v-bind:value="parent.value"
            v-bind:key="parent.value"
          >
            {{ parent.title }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="description">Description:</label>
        <textarea
          v-model="category.description"
          class="form-control"
          name="description"
          id="description"
          placeholder=""
        ></textarea>
        <div class="invalid-feedback">
          Please provide a valid description.
        </div>
      </div>

      <div class="form-group">
        <label for="meta_title">Meta Title (SEO):</label>
        <input
          type="text"
          v-model="category.meta_title"
          class="form-control"
          name="meta_title"
          id="meta_title"
          placeholder=""
        />
        <div class="invalid-feedback">Please provide a valid meta title.</div>
      </div>

      <div class="form-group">
        <label for="meta_description">Meta Description (SEO):</label>
        <textarea
          v-model="category.meta_description"
          class="form-control"
          name="meta_description"
          id="meta_description"
          placeholder=""
        ></textarea>
        <div class="invalid-feedback">
          Please provide a valid meta description.
        </div>
      </div>

      <div class="form-group">
        <label for="sort_order">Sort Order Number:</label>
        <input
          type="text"
          v-model="category.sort_order"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('category_name'),
          }"
          name="sort_order"
          id="sort_order"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid sort order number.
        </div>
      </div>
      <!-- <fieldset class="form-group"> -->
        <legend class="pt-0">Featured</legend>
        <div class="form-check">
          <input
            type="radio"
            class="form-check-input"
            name="featured"
            id="featured_0"
            value="0"
            v-model="category.featured"
          />
          <label for="featured_0" class="form-check-label">No</label>
        </div>
        <div class="form-check" style="margin-bottom: 15px">
          <input
            type="radio"
            class="form-check-input"
            name="featured"
            id="featured_1"
            value="1"
            v-model="category.featured"
          />
          <label for="featured_1" class="form-check-label">Yes</label>
        </div>
        <legend class="pt-0">Filter</legend>
        <div class="form-check">
          <input
            type="radio"
            class="form-check-input"
            name="filter"
            id="filter_0"
            value="0"
            v-model="category.filter"
          />
          <label for="filter_0" class="form-check-label">No</label>
        </div>
        <div class="form-check" style="margin-bottom: 15px">
          <input
            type="radio"
            class="form-check-input"
            name="filter"
            id="filter_1"
            value="1"
            v-model="category.filter"
          />
          <label for="filter_1" class="form-check-label">Yes</label>
        </div>
      <!-- </fieldset> -->
      <div class="form-group">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import videoParentCategories from "../data/video_parent_categories.json";

export default {
  methods: {
    saveChanges: function () {
      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token },
        };

        if (!isNaN(this.$route.params.id)) {
          this.$http
            .patch(
              process.env.VUE_APP_ROOT_API +
                "/videos/categories/" +
                this.$route.params.id,
              this.category,
              options
            )
            .then((response) => {
              if (response.status === 200) {
                alert("Record updated successfully");
              }
            });
        } else if (this.$route.params.id == "add") {
          this.$http
            .post(
              process.env.VUE_APP_ROOT_API + "/videos/categories",
              this.category,
              options
            )
            .then((response) => {
              if (response.status === 200) {
                alert("Record created successfully");
              }
            });
        }
      }
    },
  },

  data: function () {
    return {
      category: {},
      parent_categories: videoParentCategories
    };
  },

  created: function () {
    if (!isNaN(this.$route.params.id)) {
      this.$http
        .get(
          process.env.VUE_APP_ROOT_API +
            "/videos/categories/" +
            this.$route.params.id
        )
        .then((response) => {
          this.category = response.data;
        });
    } else if (this.$route.params.id !== "add") {
      alert("Invalid parameter.");
    }
  },
};
</script>
<style></style>
